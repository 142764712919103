<script setup lang="ts">
import { useDisplay } from "vuetify";
import type { I18nLanguage } from '@layouts/types'

const { mdAndUp } = useDisplay();
const emit = defineEmits(['close'])

interface Props {
  languages: I18nLanguage[]
  location?: any
}

const props = withDefaults(defineProps<Props>(), {
  location: 'bottom end',
})

const { locale } = useI18n({ useScope: 'global' })
</script>

<template>
  <IconBtn v-if="mdAndUp">
    <VIcon size="24" icon="custom-language"/>

    <!-- Menu -->
    <VMenu
      activator="parent"
      :location="props.location"
      offset="12px"
    >
      <!-- List -->
      <VList
        :selected="[locale]"
        color="primary"
        min-width="175px"
      >
        <!-- List item -->
        <VListItem
          v-for="lang in props.languages"
          :key="lang.i18nLang"
          :value="lang.i18nLang"
          @click="locale = lang.i18nLang"
        >
          <!-- Language label -->
          <VListItemTitle>{{ lang.label }}</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </IconBtn>

  <div v-else class="megamenu-margin">
    <div 
      class="ma-3 d-flex justify-end"
      v-if="!mdAndUp"
    >
      <div class="d-flex align-center" @click="emit('close')">
        <VIcon icon="custom-back"/>
      </div>
    </div>
    <VRow>
      <VCol class="text-center text-md-left">
        <div class="mx-md-6">
          <div class="d-flex flex-column mt-1 gap-1">
            <div
              v-for="lang in props.languages"
              @click="locale = lang.i18nLang"
            >
              {{ lang.label }}
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </div>
</template>
